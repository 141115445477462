body {
  height: 100vh;
  font-family: 'Ubuntu', sans-serif !important;
  font-weight: 300 !important;
}

#main {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.alert-toast {
  position: absolute;
  z-index: 1002;
  right: 10px;
  top: 10px;
  max-width: 35%;
}

.route-component {
  width: 90%;
  margin: auto;
  height: calc(100vh - 70px);
  max-width: 2000px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.scrollable {
  overflow-y: auto;
  max-height: calc(100% - 57px);
  margin: 15px auto 15px auto;
}

.sticky-table th {
  position: sticky;
  z-index: 2;
  top: 0;
  background-color: #f9fafb !important;
}

.table-cell-overflow {
  overflow-wrap: break-word;
  word-break: break-all;
}

.circle-icon-button {
  background-color: #008cff !important;
  color: white !important;
  padding: 0.3em !important;
}

.scrollable-tab-menu {
  overflow: auto;
  white-space: nowrap;
  overflow-y: hidden;
}

.date-picker {
  width: 80% !important;
}

.legend {
  list-style: none;
}
.legend li {
  float: left;
  margin-right: 5px;
}
.legend span {
  border: 1px solid #ccc;
  float: left;
  width: 12px;
  height: 12px;
  margin: 2px;
}
.legend .warning {
  background-color: #fac08b;
}

.col-h4-middle {
  margin-top: 9px !important;
}
